define("discourse/plugins/discourse-word-cloud/discourse/initializers/word-cloud-inits", ["exports", "discourse/lib/plugin-api", "discourse-common/resolver"], function (_exports, _pluginApi, _resolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'word-cloud-inits',
    initialize(container) {
      const currentUser = container.lookup("current-user:main");
      const siteSettings = container.lookup("site-settings:main");
      const isMobileDevice = (0, _resolver.getResolverOption)("mobileView");
      if (!siteSettings.word_cloud_enabled || !currentUser || isMobileDevice) return;
      (0, _pluginApi.withPluginApi)('0.8.13', api => {
        api.decorateWidget("hamburger-menu:generalLinks", function (helper) {
          return {
            href: "/wordcloud",
            rawLabel: I18n.t('word_cloud.hamburger_menu_label')
          };
        });
      });
    }
  };
});